import {
  SET_IS_MODULE_LOADED,
  SetGeneralError,
  SetInputValidated,
  SET_INPUT_SD_BUSINESS_WORK_WITH_US,
  SdWorkWithUsActions,
  SET_PHONE_INPUT_SD_BUSINESS_WORK_WITH_US,
  SetPhoneInputValidated,
  SET_FORM_LOADING_SD_BUSINESS_WORK_WITH_US,
  SetFormLoading,
  SET_FORM_SUCCESS_SD_BUSINESS_WORK_WITH_US, SetFormSuccess,
  SetCheckboxValidated,
  SET_CHECKBOX_WORK_WITH_US,
  SET_GENERAL_ERROR_SD_BUSINESS_WORK,
  RESET_FORM_SD_BUSINESS_WORK_WITH_US,
} from './actions';

export type SdWorkWithUsData = {
  isModuleLoaded: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  firstName: {
    value: string;
    error: string;
  };
  lastName: {
    value: string;
    error: string;
  };
  phoneNumber: {
    countryCode: string;
    number: string;
    error: string;
  };
  email: {
    value: string;
    error: string;
  };
  companyName: {
    value: string;
    error: string;
  };
  industry: {
    value: string;
    error: string;
  };
  dailyVolume: {
    value: string;
    error: string;
  }
  recaptcha: {
    value: string;
    error: string;
  };
  receiveEmails: {
    value: {[key: string]: boolean};
    error: string;
  };
  generalError: string;
}

export const initialState: SdWorkWithUsData = {
  isModuleLoaded: false,
  isLoading: false,
  isSuccess: false,
  firstName: {
    value: '',
    error: '',
  },
  lastName: {
    value: '',
    error: '',
  },
  phoneNumber: {
    countryCode: '+971',
    number: '',
    error: '',
  },
  email: {
    value: '',
    error: '',
  },
  companyName: {
    value: '',
    error: '',
  },
  industry: {
    value: '',
    error: '',
  },
  dailyVolume: {
    value: '',
    error: '',
  },
  recaptcha: {
    value: '',
    error: '',
  },
  receiveEmails: {
    value: {},
    error: '',
  },
  generalError: '',
};

const SdWorkWithUs = (
  state: SdWorkWithUsData = initialState,
  action: SdWorkWithUsActions,
): SdWorkWithUsData => {
  switch (action.type) {
    case SET_IS_MODULE_LOADED:
      return {
        ...state,
        isModuleLoaded: true,
      };
    case SET_INPUT_SD_BUSINESS_WORK_WITH_US:
      return {
        ...state,
        ...(action as SetInputValidated).payload,
      };
    case SET_PHONE_INPUT_SD_BUSINESS_WORK_WITH_US:
      return {
        ...state,
        ...(action as SetPhoneInputValidated).payload,
      };
    case SET_FORM_LOADING_SD_BUSINESS_WORK_WITH_US: {
      return {
        ...state,
        isLoading: (action as SetFormLoading).payload.isLoading,
      };
    }
    case SET_FORM_SUCCESS_SD_BUSINESS_WORK_WITH_US: {
      return {
        ...state,
        isSuccess: (action as SetFormSuccess).payload.isSuccess,
      };
    }
    case SET_CHECKBOX_WORK_WITH_US:
      return {
        ...state,
        ...(action as SetCheckboxValidated).payload,
      };
    case SET_GENERAL_ERROR_SD_BUSINESS_WORK: {
      return {
        ...state,
        generalError: (action as SetGeneralError).payload,
      };
    }
    case RESET_FORM_SD_BUSINESS_WORK_WITH_US: {
      return initialState;
    }
    default:
      return state;
  }
};

export default SdWorkWithUs;
